$bg-color-primary: #35707d;
$bg-color-secondary: var(--fuse-primary);
$base-color: #fff;

.rm-checkbox.mat-checkbox {
  .mat-checkbox-background {
    background-color: $bg-color-primary;
    border: 1px solid $base-color;
    stroke: $base-color;

    .mat-checkbox-checkmark-path {
      stroke: $base-color !important;
    }

    .mat-checkbox-mixedmark {
      background-color: $base-color;
    }
  }
}

.rm-checkbox.mat-checkbox.mat-checkbox-checked {
  .mat-checkbox-background {
    background-color: $bg-color-secondary !important;
    border: 1px solid $bg-color-secondary !important;
    stroke: $bg-color-secondary !important;
  }
}

.rm-white-checkbox.mat-checkbox {
  .mat-checkbox-frame {
    border-color: $base-color !important;
    border-width: 1px !important;
  }
}

.rm-white-checkbox.mat-checkbox.mat-checkbox-checked {
  .mat-checkbox-background {
    background-color: $base-color !important;
    border: 1px solid $base-color !important;
    stroke: $base-color !important;
  }
}
