.event-marker {
  position: absolute;
  display: flex;
  align-items: center;
  font-family: 'Sofia Pro';
  opacity: 0.5;

  &.active {
    opacity: 1;
  }

  .marker {
    height: 3rem;
    width: 3rem;
    cursor: pointer;
    pointer-events: auto;
  }

  .marker-label {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 1.0989rem;
    line-height: 1.8571428571rem;
    margin-left: 6px;
  }

  &.normalEvents {
    color: #04b4cd;
  }

  &.criticalEvents {
    color: #ff5630;
  }
}
