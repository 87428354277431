/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import url(./rmstyles.scss);
@import 'partials';

@import url(./rmstyles.scss);

html,
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  font-family: 'Sofia Pro';
  font-size: 14px;
}

button,
.mat-button {
  font-family: 'Sofia Pro';
  font-weight: 400;
  font-size: 14px;
}

.pager-text {
  // opacity        : 0.6;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

mat-option {
  background-color: white !important;
  color: black !important;
}

mat-option.mat-option-disabled {
  color: #64748b !important;
}

.pager-input {
  width: 30px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #fff;
  color: #fff;
  text-align: center;
}

// .pagination{
//     padding: 5px 0px 0px 0px;

//     .list-count {
//       mat-form-field {
//         width : 50px;
//         height: 40px;
//       }
//     }

//     .page-notify {
//       .footer-pagination {
//         background: transparent;
//         border    : none;
//         color     : #fff;
//         padding   : 5px 0px 0px 0px;

//         .footer-arrow {
//           font-size: 30px;
//         }
//       }

//       .page-input {
//         width           : 30px;
//         background-color: transparent;
//         border          : none;
//         border-bottom   : 1px solid #fff;
//         color           : #fff;
//       }
//     }
// }

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &.pt-5 {
    padding-top: 5px !important;
  }

  &.prbl-0 {
    padding-left: 23px;
    padding-right: 23px;
    padding-bottom: 0px;
  }

  .list-count {
    display: flex;
    align-items: center;

    span {
      color: #fff;
    }

    mat-form-field {
      width: 135px;
      min-height: 40px;
      border-radius: 3px;

      mat-form-field-infix {
        width: 145px !important;
        padding: 0px 0px 3px 40px !important;
      }

      mat-select {
        width: calc(100% - 55px);
      }
    }
  }

  .page-notify {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 37%;

    .footer-pagination {
      background: transparent;
      border: none;
      color: #fff;
      height: 25px;
      width: 25px;
      order: 2;
      padding: 0px 20px;
      margin-top: -5px;
    }

    .pagination-left-arrow,
    .pagination-right-arrow {
      font-size: 30px;
      display: flex;
    }

    .page-count {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      height: 100%;
      order: 1;

      span {
        margin: 0 5px;
      }

      .mat-form-field.mat-form-field-appearance-fill
        .mat-form-field-wrapper
        .mat-form-field-flex {
        min-height: 38px;
        background-color: black;
      }

      .page-count-total {
        padding: 0 5px;

        span {
          padding: 0 5px;
        }
      }
    }
  }
}

// .mat-select-panel-wrap
//   .mat-select-panel:not(.bulk-actions-dropdown):not(.robotmaps-dropdown):not(.robots-dropdown):not(.breadcrumb-dropdown) {
//   position: absolute;
//   bottom: 32px;
//   width: auto;
//   min-width: 100%;

//   .mat-active {
//     background: #04b4cd !important;
//     color: #ffffff !important;
//   }
// }

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #414141;
  border: 0px none #ffffff;
  border-radius: 0px;

  &:hover {
    background: #414141;
  }

  &:active {
    background: #414141;
  }
}

::-webkit-scrollbar-track {
  background: #fff0;
  border: 0px none #ffffff;
  border-radius: 0px;

  &:hover {
    background: #fff0;
  }

  &:active {
    background: #fff0;
  }
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background: rgba(#000, 0.16);

  mat-spinner {
    width: 75px !important;
    height: 75px !important;

    svg {
      width: 75px !important;
      height: 75px !important;
    }
  }
}

.mat-menu-panel {
  background-color: #ffffff !important;

  .mat-menu-item {
    color: #172b4d !important;
  }

  color: #172b4d !important;
}

.mat-menu-panel.tool-menu-item {
  background-color: #ffffff !important;
  color: #172b4d !important;
  position: absolute;
  left: 40px;
  top: -40px;
  min-height: 0px;

  &.expanded {
    left: 0px !important;
    top: 10px !important;
  }

  .mat-menu-content {
    padding-top: 0px;
    padding-bottom: 0px;

    .mat-menu-item {
      color: #172b4d !important;

      &.warning {
        color: red !important;
      }

      &.info {
        color: rgb(0, 81, 255) !important;
      }

      &.success {
        color: rgb(6, 177, 6) !important;
      }

      .mat-icon-no-color {
        &.warning {
          color: red !important;
        }

        &.info {
          color: rgb(0, 81, 255) !important;
        }

        &.success {
          color: rgb(6, 177, 6) !important;
        }
      }
    }
    .mat-menu-item:hover {
      background-color: #ececec !important;
    }
  }
}

body.dark .mat-menu-item .mat-icon-no-color,
body.dark .mat-menu-submenu-icon,
body .dark .mat-menu-item .mat-icon-no-color,
body .dark .mat-menu-submenu-icon {
  color: #172b4d !important;
}

.mat-menu-item {
  color: #172b4d !important;
}

.mat-menu-item:hover {
  background-color: #ececec !important;
  // color: #172B4D !important;
}

.mat-menu-item:hover {
  background-color: #ececec !important;
  // color: #172B4D !important;
}

@media screen and (min-width: 1600px) {
  .thumbnail-card-paginator {
    position: fixed;
    right: 30px;
    bottom: 40px;
  }
}

.robotmaps-dropdown.mat-select-panel {
  position: absolute;
  min-width: calc(100% + 20px) !important;
  top: 46px;
  left: 16px;
}

.robots-dropdown.mat-select-panel {
  position: absolute;
  min-width: 293px !important;
  top: 46px;
  left: 16px;
}

.filter.mat-select-panel {
  position: absolute;
  min-width: 200px !important;
  top: 35px;
  left: 6px;
}

.select-on-popup {
  margin-top: 43px;
}

.select-on-popup-apr-none {
  margin-top: 40px;
}

.bulk-actions-dropdown {
  margin-top: 50px;
}

.select-camera-direction,
.video-dashboard-robot-select {
  margin-top: 35px;
  margin-left: 6px;
  min-width: calc(100% + 20px) !important;
}

.invite-role-select {
  margin-top: 30px;
}

.right-sidebar-opened {
  width: 100%;
}

.sensor-in-popup-selector {
  min-width: calc(100% + 20px) !important;
  margin-left: 16px;
}

.layout-lift-select,
.door-type-select,
.graph-select,
.access-key-select {
  margin-top: 45px;
  margin-left: 16px;
  min-width: calc(100% + 20px) !important;
}

.invite-role-select {
  min-width: calc(100%) !important;
  margin-left: 16px;
  margin-top: 30px;
}

.robot-model-select {
  margin-top: 45px;
  margin-left: 40px;
  min-width: calc(100% + 20px) !important;
}

app-monitoring mat-accordion.container-list mat-expansion-panel,
app-edit-layout mat-accordion.container-list mat-expansion-panel {
  .mat-expansion-panel-body {
    padding: 0px 0px 10px;
  }
}

mat-tooltip-component .mat-tooltip.custom-tooltip {
  position: relative !important;
  margin-bottom: -3px !important;
}

mat-tooltip-component .mat-tooltip.custom-right-tooltip {
  position: relative !important;
  margin-left: 5px !important;
}

mat-tooltip-component .mat-tooltip.custom-bottom-tooltip {
  position: relative !important;
  margin-top: 0px !important;
}

.robotModel {
  min-width: calc(100% + 20px) !important;
  margin-top: 48px;
  margin-left: 16px;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: #083a45 !important;
}

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  background-color: white !important;
  color: #083a45 !important;
  margin-top: 8px;
  margin-left: -10px;

  .mat-calendar-next-button::after {
    border-right-width: 2px;
    transform: translateX(-2px) rotate(45deg);
    color: black;
  }

  .mat-calendar-previous-button::after {
    border-left-width: 2px;
    transform: translateX(2px) rotate(-45deg);
    color: black !important;
  }

  span.mat-button-wrapper {
    color: black !important;
  }

  .mat-calendar-table-header {
    color: black !important;
  }

  .mat-calendar-body-label {
    color: black !important;
  }

  .mat-calendar-body-cell-content.mat-focus-indicator {
    color: black !important;
  }

  .mat-calendar-body-cell-content.mat-focus-indicator.mat-calendar-body-selected {
    border-bottom: 1px solid #04b4cd !important;
    border-radius: unset;
  }

  ::ng-deep.mat-calendar-body-selected {
    background-color: unset !important;
    color: #04b4cd !important;
    color: #04b4cd !important;
  }

  .mat-calendar-body-cell-content.mat-focus-indicator.mat-calendar-body-today {
    /* background: red; */
    border-radius: unset;
    color: #0f766e !important;
    border-bottom: 1px solid #04b4cd !important;

    :hover {
      border-bottom: 1px solid #04b4cd !important;
    }
  }

  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ),
  body
    .dark
    .mat-calendar-body-today:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    border-color: unset !important;
  }

  .mat-calendar-body-cell-content.mat-focus-indicator.mat-calendar-body-today:hover {
    background: red !important;
  }
}
