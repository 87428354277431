.map-container {
  position: relative;
  width: 100%;
  height: 100%;

  .map {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    background: #011b24;
  }

  /* Layout map custom marker */
  .custom-marker-pin {
    // position: relative;
    .marker-circle {
      width: 28px;
      height: 28px;
      position: absolute;
      top: 0;
      left: 0;
      mask-image: url(/assets/images/markers/marker-circle.svg);
      mask-repeat: no-repeat;
      mask-size: contain;

      &.brown {
        background-color: #7d490b;
      }
    }

    .marker-pin {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      mask-image: url(/assets/images/markers/marker-grey.svg);
      mask-repeat: no-repeat;
      mask-size: contain;

      &.floor-pin {
        top: -10px;
      }

      .marker-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 24px;
        height: 24px;
        margin-top: -22px;
        margin-left: -12px;
        overflow: hidden;
        border-radius: 50%;

        &.sensor-icon {
          width: 30px;
          height: 30px;
          margin-top: -20px;
          margin-left: -15px;
          border-radius: 0;
        }

        &.floor-icon-lift {
          width: 15px;
          height: 15px;
          margin-top: -10px;
          margin-left: -7px;
          border-radius: 0;
        }

        &.floor-icon-mark {
          width: 24px;
          height: 24px;
          margin-top: -17px;
          margin-left: -12px;
          border-radius: 0;
        }

        &.lift-icon-transit {
          width: 30px;
          height: 30px;
          margin-top: -23px;
          margin-left: -14px;
          border-radius: 0;
        }

        &.lift-icon-waiting {
          width: 48px;
          height: 48px;
          margin-top: -27px;
          margin-left: -24px;
          border-radius: 0;
        }

        &.lift-icon-exit {
          width: 48px;
          height: 72px;
          margin-top: -34px;
          margin-left: -24px;
          border-radius: 0;
        }

        &.lift-icon-waiting-lift {
          width: 48px;
          height: 72px;
          margin-top: -34px;
          margin-left: -24px;
          border-radius: 0;
        }

        &.charging-icon {
          width: 20px;
          height: 36px;
          margin-top: -27px;
          margin-left: -10px;
          border-radius: 0;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
      .marker-icon-fire-holding {
        width: 30px;
        height: 30px;
        margin-top: -22px;
        margin-left: -15px;
      }

      &.blue {
        background-color: #4c9aff;
      }

      &.red {
        background-color: #ff7452;
        .spec-excu {
          width: 70%;
          height: 70%;
          left: 34%;
          top: 42%;
        }
      }
      &.dangerRed {
        background-color: #bf2600;
        .spec-excu {
          width: 60%;
          height: 100%;
          left: 40%;
          top: 40%;
        }
      }

      &.yellow {
        background-color: #ffc400;
        .spec-excu {
          height: 100%;
          top: 40%;
        }
      }

      &.grey {
        background-color: #bfbfbf;
      }

      &.violet {
        background-color: #8777d9;
      }

      &.green {
        background-color: #2aad27;
      }

      &.indigo {
        background-color: #04b4cd;
      }
    }

    .marker-shadow {
      background: rgba(32, 22, 75, 0.62);
      border-radius: 50%;
      height: 14px;
      width: 14px;
      position: absolute;
      left: 50%;
      top: -4px;
      margin: 29px 0 0 -7px;
      transform: rotateX(55deg);
      z-index: -2;

      &:after {
        content: '';
        border-radius: 50%;
        height: 40px;
        width: 40px;
        position: absolute;
        margin: -16px 0 0 -13px;
        animation: pulsate 2.5s ease-out;
        animation-iteration-count: infinite;
        opacity: 0;
        background: rgba(32, 22, 75, 0.49);
        box-shadow: 0 0 1px 2px #2d99d3;
        animation-delay: 1.1s;
      }
    }

    .linkarea-pin {
      top: 4px;
    }

    .linkarea-marker-shadow {
      background: rgba(32, 22, 75, 0.62);
      border-radius: 50%;
      height: 14px;
      width: 14px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin: 29px 0 0 -6px;
      transform: rotateX(55deg);
      z-index: -2;

      &:after {
        content: '';
        border-radius: 50%;
        height: 40px;
        width: 40px;
        position: absolute;
        margin: -16px 0 0 -13px;
        animation: pulsate 2.5s ease-out;
        animation-iteration-count: infinite;
        opacity: 0;
        background: rgba(32, 22, 75, 0.49);
        box-shadow: 0 0 1px 2px #2d99d3;
        animation-delay: 1.1s;
      }
    }

    .marker-label {
      width: 100px;
      max-height: 35px;
      font-size: 0.8571428571rem;
      line-height: 1rem;
      color: white;
      position: absolute;
      text-align: center;
      top: -20px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      z-index: 10;
      background-color: #333;
      padding: 3px;
      left: -20px;

      &.floor-label {
        top: -36px;
        left: -32px;
        font-size: 0.7571428571rem !important;
      }
    }

    .lift-marker-label {
      width: 100px;
      max-height: 35px;
      font-size: 0.8571428571rem;
      line-height: 1rem;
      color: white;
      position: absolute;
      text-align: center;
      top: -20px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      z-index: 10;
      background-color: #333;
      padding: 3px;
      left: -20px;

      &.floor-label {
        top: -10px;
        left: -32px;
      }
    }

    .event-flag {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ff5630;
      border-radius: 50px;

      img {
        width: 14px;
        height: 14px;
      }
    }

    &.leaflet-marker-icon {
      transition: opacity 0.1s ease-in-out;
      &:hover {
        opacity: 1 !important;
      }
    }

    .zone-node {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #fff;
      border: 2px solid #333;
    }
  }

  .node-waiting-point-marker-pin {
    .node-wp-marker-label {
      background: rgba(#333, 0.8);
      max-width: 100px;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #fff;
      font-size: 10px;
      padding: 1px 5px;
      border: none;
      border-radius: 0px;
    }
  }

  //label for door
  .door-marker-label {
    background: rgba(#333, 0.8);
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #fff;
    font-size: 10px;
    padding: 1px 5px;
    border: none;
    border-radius: 0px;
  }

  // zone area name label
  .zone-label {
    background: rgba(#333, 0.8);
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #fff;
    font-size: 0.8571428571rem;
    padding: 1px 5px;
    border: none;
    border-radius: 0px;
  }

  .lift-graph-mark-display {
    display: none !important;
  }

  .font-wrap-marker {
    word-wrap: break-word;
  }
}

// OVERLAY
.layout-filter {
  bottom: 0;
  left: 0;
  position: absolute;
  display: flex;
  margin: 40px 30px;
  z-index: 50;

  .filter-item {
    background-color: #011b24;
    grid-gap: 0.75rem;
    gap: 0.75rem;
    padding: 0.75rem 1rem;
    align-items: center;
    display: flex;
    cursor: pointer;
    user-select: none;
    opacity: 0.5;

    .filter-icon {
      color: #ffffff;
      width: auto;
      height: auto;
      min-width: 0;
      min-height: 0;
    }

    &.active {
      opacity: 1;
    }
  }
}

@mixin setItemColor($color) {
  background-color: $color;
  border-color: $color;
}

.layout-control {
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  margin: 40px 20px;
  z-index: 50;

  .item {
    width: 48px;
    height: 40px;
    border: 1px solid transparent;
    border-radius: 3px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    @include setItemColor(rgba(37, 56, 88, 0.33));

    .icon {
      color: #ffffff;
    }
  }

  .zoom-percentage {
    @include setItemColor(rgba(0, 0, 0, 0.71));
  }
}

body.dark {
  .mat-accent {
    .mat-slider-thumb {
      background-color: #06b4cd;
    }
    .mat-slider-track-fill {
      background-color: #06b4cd;
    }
  }
}
