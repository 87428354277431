table.mat-table.rm-data-table {
  th {
    background-color: #086d7b;
    color: #ffffff;
    font-weight: bold;
  }

  td.mat-cell {
    border-bottom: 0;
  }

  .sticky-right-border {
    box-shadow: -1px 0px 0px #148998 inset;
  }

  .mat-column-select {
    width: 70px;
  }

  .mat-checkbox {
    .mat-checkbox-background {
      background-color: #35707d;
      border: 1px solid;
      stroke: #fff;

      .mat-checkbox-checkmark-path {
        stroke: #fff !important;
      }

      .mat-checkbox-mixedmark {
        background-color: #fff;
      }
    }
  }
}
