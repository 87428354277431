.mission-1 {
  background: #dffbff !important;
  opacity: 1;
  color: #000000;
}

.mission-4 {
  background: #7a869a !important;
  color: #ffffff;
}

.mission-5 {
  background: #04b4cd !important;
  color: #000000;
}

.mission-3 {
  background: #ffab00 !important;
  color: #000000;
}

.mission-2 {
  background-color: red !important;
  color: #000000;
}

.mission-failed {
  background-color: red !important;
  color: #000000;
}

.mission-scheduled {
  background: #ffab00;
}

.mission-status-button {
  left: 0;
  border-radius: 3px !important;
  font-size: 12px;
  text-align: center !important;
  cursor: default !important;
  padding: 5px !important;
}
