.rm-tab {
  .mat-tab-label-container {
    border-bottom: 1px solid #00363d !important;
  }

  .mat-tab-list {
    margin-left: 7px;
  }

  .mat-tab-labels {
    gap: 20px;

    .mat-tab-label {
      font-size: 14px !important;
      font-weight: 700;
      border-radius: 0 !important;
      padding: 0 !important;
      color: white !important;
    }
    .mat-tab-label.mat-tab-label-active {
      color: #04b4cd !important;
      border-bottom: 2px solid #04b4cd;
      background-color: transparent !important;
    }
    .mat-tab-label.mat-tab-disabled {
      cursor: not-allowed;
      .mat-tab-label-content {
        color: #505f79;
      }
    }
  }

  .mat-tab-body-wrapper {
    padding-bottom: 2.5rem !important;
  }
}

.mat-tab-group {
  max-height: 88vh;
}
